import React from 'react';
import { graphql } from 'gatsby';
import Link from 'gatsby-link';
import Img from 'gatsby-image';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import FormatHtml from 'components/utils/FormatHtml';
import { ImageSharpFluid } from 'helpers/definitions';

import * as Styled from './styles';
import Lightbox from 'components/Lightbox';

interface Post {
	html: React.ReactNode;
	fields: {
		slug: string;
	};
	frontmatter: {
		title: string;
		date: string;
		cover?: {
			childImageSharp: {
				fluid: ImageSharpFluid;
			};
		};
		gallery?: [
			{
				caption?: string;
				alt?: string;
				img: {
					childImageSharp: {
						fluid: ImageSharpFluid;
					};
				};
			}
		];
	};
}

interface Props {
	data: {
		markdownRemark: Post;
	};
	pageContext: {
		slug: string;
		next: Post;
		previous: Post;
	};
}

const BlogPost: React.FC<Props> = ({ data, pageContext }) => {
	const post = data.markdownRemark;
	const { cover, gallery, title, date } = post.frontmatter;
	const { previous, next } = pageContext;

	return (
		<Layout>
			<SEO title={title} />
			<Container section>
				{cover && (
					<Styled.Image>
						<Img fluid={cover.childImageSharp.fluid} alt={title} />
					</Styled.Image>
				)}
				<TitleSection title={title} subtitle={date} />
				<FormatHtml content={post.html} />
				{gallery &&
					// <Lightbox images={gallery} /> // TODO: add working Lightbox?
					gallery.map((item) => (
						<Styled.Image key={(item.img.childImageSharp.fluid as any).src}>
							<Img fluid={item.img.childImageSharp.fluid} alt={item.alt} />
							{item.caption && <figcaption>{item.caption}</figcaption>}
						</Styled.Image>
					))}
				<Styled.Links>
					<span>
						{previous && (
							<Link to={previous.fields.slug} rel="previous">
								← {previous.frontmatter.title}
							</Link>
						)}
					</span>
					<span>
						{next && (
							<Link to={next.fields.slug} rel="next">
								{next.frontmatter.title} →
							</Link>
						)}
					</span>
				</Styled.Links>
			</Container>
		</Layout>
	);
};

export default BlogPost;

export const query = graphql`
	query BlogPostBySlug($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
				date(formatString: "YYYY-MM-DD")
				cover {
					childImageSharp {
						fluid(maxWidth: 768) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				gallery {
					alt
					caption
					img {
						childImageSharp {
							fluid(maxWidth: 768) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
			}
		}
	}
`;
